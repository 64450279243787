import {Component, Provide, Vue, Watch} from 'vue-property-decorator';
import {PhoneLoginReq, VerificationCodeReq} from "@/interface/res/login";
import {RouteItem} from "@/interface/pagas/login";
import PageRouter from "@/router/PageRouter";
import {mapGetters} from "vuex";
import {phone} from "@/utils/regExp";
import {OauthBindAccountApi, SmsPhoneLoginApi} from "@/network/modules/login";

@Component({
  components: {
  },
  computed: {
    ...mapGetters(["userInfo"])
  }
})
export default class WeChatLogin extends Vue {
  @Provide() iQuery: any = {}

  @Provide() info: {
    accountId:string,
    headimgurl:string,
    nickname:string,
    routeCode:string,
    token:string,
    unionid:string,
  } = {
    accountId:'',
    headimgurl:'',
    nickname:'',
    routeCode:'',
    token:'',
    unionid:'',
  }
  @Provide() loginType: number = 0

  @Provide() form:PhoneLoginReq = {
    phone:"",
    device:"PC",
    phoneCode:"",
    unionId:""
  };
  @Provide() phoneMsg: string = ''
  @Provide() msgType: string = 'err'
  @Provide() phoneCodeMsg: string = ''
  @Provide() codeNum: number = 60
  @Provide() codeLoading: boolean = false
  @Provide() loading: boolean = false

  @Provide() routeLink:string = ''
  @Provide() routeItem:RouteItem = {name:"",config:"",key:"",}

  @Watch('userInfo')
  private iDataChange():void {
    const _this:any = this;
    if(_this.$base.isNull(localStorage.getItem('_TOKEN')))return
    if(_this.$base.isNull(_this.routeLink)){
      _this.$base.goRouter(_this.$IndexConfig.home.path)
      return;
    }
    let code = _this.iQuery
    for (let codeKey in code) {
      code[codeKey] = _this.$decryptBy(code[codeKey])
    }
    delete code.r
    let codeStr = _this.$base.serializeStr(code)
    if(_this.routeItem.config == 'IndexConfig'){
      const link = _this.$base.routeLinkSplice(_this.$IndexConfig[_this.routeItem.key].path,codeStr)
      _this.$base.goRouter(link)
      return;
    }
    if(_this.routeItem.config == 'LoginConfig'){
      const link = _this.$base.routeLinkSplice(_this.$LoginConfig[_this.routeItem.key].path,codeStr)
      _this.$base.goRouter(link)
      return;
    }
    if(_this.routeItem.config == 'MineConfig'){
      const link = _this.$base.routeLinkSplice(_this.$MineConfig[_this.routeItem.key].path,codeStr)
      _this.$base.goRouter(link)
      return;
    }
  }

  created(){
    const _this:any = this;
    _this.info = _this.$route.query
    _this.form.unionId = _this.info.unionid
    if(_this.$base.isNull(_this.info.token)){
      _this.loginType = 2
    }
    else{
      _this.loginType = 1
      let a = setTimeout(()=>{
        _this.$store.commit('SET_TOKEN',_this.info.token)
        clearTimeout(a)
      },1500)

    }

    if(!_this.$base.isNull(_this.$route.query.routeCode)){
      _this.iQuery = _this.$base.serializeJson(_this.$route.query.routeCode,'*',"-")
      _this.routeLink = _this.$decryptBy(_this.iQuery.r)
      _this.routeItem = PageRouter.filter((item:any) => item.key == _this.routeLink)[0]
    }
  }

  /**
   * @Author HS
   * @Date 2021/8/31 2:14 下午
   * @Description: 绑定登录
   * @Params: null
   * @Return: null
  */
  async loginFn(){
    const _this:any = this;

    if(!phone.test(_this.form.phone)){
      _this.phoneMsg = "请输入正确的手机号码"
      return
    }
    _this.phoneMsg = ""

    if(_this.$base.isNull(_this.form.phoneCode)){
      _this.msgType = 'err'
      _this.phoneCodeMsg = "请输入验证码"
      return
    }
    _this.phoneCodeMsg = ""

    _this.loading = true
    let params:PhoneLoginReq = {..._this.form}
    const res = await OauthBindAccountApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code , msg } = res
    if(code != 200 ){
      _this.msgType = 'err'
      _this.phoneCodeMsg = msg
      return
    }
    _this.phoneCodeMsg = ""
    _this.loginType = 2
    let b = setTimeout(()=>{
      _this.$store.commit('SET_TOKEN',data.token)
      clearTimeout(b)
    },1500)
  }



  /**
   * @Author HS
   * @Date 2021/6/29 3:35 下午
   * @Description: 获取验证码
   * @Params: { 类型 ： 字段 - 是否必须 } [参数：]
   * @Return:
  */
  async getVerificationCodeFn(){
    const _this:any = this;
    if(_this.codeLoading)return

    if(!phone.test(_this.form.phone)){
      _this.phoneMsg = "请输入正确的手机号码"
      return
    }
    _this.phoneMsg = ""

    _this.codeLoading = true
    let params:VerificationCodeReq = {
      phone:_this.form.phone
    }
    const res = await SmsPhoneLoginApi(params)
    let a = setTimeout(()=>{_this.codeLoading = false;clearTimeout(a)},500)
    const { code , msg } = res
    if(code != 200 ){
      _this.phoneMsg = msg
      return
    }

    _this.phoneMsg = ""
    _this.codeNum = 59
    _this.countdownFn()
  }

  /**
   * @Author HS
   * @Date 2021/6/29 3:17 下午
   * @Description: 倒计时
   * @Params: null
   * @Return: null
  */
  countdownFn(){
    const _this:any = this
    let time = setTimeout(()=>{
      _this.codeNum--

      if(_this.codeNum > 0){
        clearTimeout(time)
        _this.countdownFn()
        return
      }
      _this.codeNum = 60
      clearTimeout(time)
    }, 1000);
  }

  /**
   * @Author HS
   * @Date 2021/6/29 2:19 下午
   * @Description: 页面跳转
   * @Params: { string ： path - true } [path：路径]
   * @Return: null
   */
  goRouter(path:string){
    const _this:any = this;
    let code = _this.$route.query
    for (let codeKey in code) {
      code[codeKey] = _this.$decryptBy(code[codeKey])
    }
    let codeStr = _this.$base.serializeStr(code)
    const link = _this.$base.routeLinkSplice(_this.$LoginConfig[path].path,codeStr)
    _this.$base.goRouter(link)
  }

}
